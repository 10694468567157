import React, {Component} from 'react';

class Image extends Component {
    render() {
        return(
            <>
                <h1>Bryan Rojas</h1>
                <p>Software Engineer | People Helper | Tech Enthusiast</p>
            </>
        )
    }
}
  
export default Image